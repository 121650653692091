<template>
  <!-- 网点管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-button v-if="userPrower == '1'" type="success" icon="el-icon-plus" size="mini" @click="toAddStorePage">新增网点</el-button>
      <div class="right-search">
        <span>所属地区：</span>
        <el-select v-model="sotreArea" filterable :disabled="loading" clearable placeholder="请选择地区" size="mini" style="width: 160px;">
          <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
        <span style="margin-left: 20px;">网点名称：</span>
        <el-input v-model="storeName" clearable placeholder="请输入名称" :disabled="loading" size="mini" style="width: 230px;"></el-input>
        <el-button type="primary" icon="el-icon-search" size="mini" :disabled="loading" style="margin-left: 20px;" @click="onSearch">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%; font-size: 12px; margin: 20px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        prop="storecode"
        label="网点编号"
        width="150">
      </el-table-column>
      <el-table-column
        prop="storename"
        label="网点名称">
      </el-table-column>
      <el-table-column
        prop="area"
        label="所属地区"
        width="200">
      </el-table-column>
      <el-table-column
        prop="fname"
        label="团队经理"
        width="150">
      </el-table-column><el-table-column
        prop="sname"
        label="规划师"
        width="150">
      </el-table-column>
      <el-table-column v-if="userPrower == '1'" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-edit" @click="onUpdate(scope)">编辑</el-button>
          <el-button type="text" size="mini" icon="el-icon-delete" @click="onDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
    :current-page.sync="currentPage"
    :page-size="pageSize"
    hide-on-single-page
    background
    layout="total, prev, pager, next"
    :total="total"
    @current-change="handleCurrentChange">
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: 'StorePage',

  data() {
    return {
      userPrower: '', // 用户权限
      areaOptions: [], // 地区
      sotreArea: '', // 网点所属地区
      storeName: '', // 网点名称
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },

  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.userPrower = userInfo.prower
    } else {
      this.$http({
        method: 'post',
        url: 'user_exit'
      })
      this.$router.push({
        path: '/login'
      })
    }
    this.initData()
    this.getData()
  },

  methods: {
    initData() {
      this.$http({
        method: 'post',
        url: 'area_findall'
      })
      .then(res => {
        if (res.errno == 0) {
          this.areaOptions = res.data.data
        } else {
          this.$message.error('地区查询失败')
        }
      })
      .catch(err => {
        this.$message.error('地区查询失败')
      })

      if (!sessionStorage.getItem('storeList')) {
        this.$http({
          method: 'post',
          url: 'store_findall'
        })
        .then(res => {
          if (res.errno == 0) {
            sessionStorage.setItem('storeList', JSON.stringify(res.data.data))
          }
        })
      }

    },
    getData() {
      this.loading = true
      this.$http({
        method: 'post',
        url: 'store_find',
        params: {
          storename: this.storeName,
          area: this.sotreArea,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      })
      .then(res => {
        this.loading = false
        if (res.errno == 0) {
          const { data, page, pageSize, count } = res.data
          this.tableData = data
          this.currentPage = page
          this.pageSize = pageSize
          this.total = count
        } else {
          this.$message.error('查询失败')
        }
      })
      .catch(err => {
        this.loading = false
        this.$message.error('服务端异常，查询失败')
        console.error('查询异常：', err.message);
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    onSearch() {
      this.currentPage = 1
      this.getData()
    },
    toAddStorePage() {
      this.$router.push({
        path: '/addStore'
      })
    },
    // 页码改变
    handleCurrentChange(page) {
      this.currentPage = page
      this.getData()
    },
    onUpdate(scope) {
      sessionStorage.setItem('updateStore', JSON.stringify(scope.row))
      this.$router.push({
        path: '/updateStore',
        query: {
          id: scope.row.id
        }
      })
    },
    onDelete(scope) {
      this.$confirm(`确定要删除 ${scope.row.storename} 吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            method: 'post',
            url: 'store_delete',
            params: {
              id: scope.row.id
            }
          })
          .then(res => {
            if (res.errno == 0) {
              this.$message.success('删除成功')
              this.getData()
            } else {
              this.$message.error('服务端异常，网点删除失败')
            }
          })
          .catch(err => {
            this.$message.error('服务端异常，网点删除失败')
          })
        }).catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>